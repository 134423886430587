<template>
  <div>
    <h1>Login</h1>
    <form @submit.prevent="login">
      <div>
        <label for="username">Username</label>
        <input type="text" v-model="user.username">
      </div>
      <div>
        <label for="password">Password</label>
        <input type="password" v-model="user.password">
      </div>
      <button type="submit">Login</button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        username: '',
        password: ''
      }
    };
  },
  methods: {
    login() {
      console.log('Inside post request');
      fetch('http://localhost:8024/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.user)
      }).then(response => {
        if (response.ok) {
          // Handle successful login
          this.$router.push('/posts');
        } else {
          // Handle error
        }
      });
    }
  }
};
</script>
