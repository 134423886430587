<template>
  <div>
    <h1>Register</h1>
    <form @submit.prevent="register">
      <div>
        <label for="username">Username</label>
        <input type="text" v-model="user.username">
      </div>
      <div>
        <label for="password">Password</label>
        <input type="password" v-model="user.password">
      </div>
      <button type="submit">Register</button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'RegisterPage',
  data() {
    return {
      user: {
        username: '',
        password: ''
      }
    };
  },
  methods: {
    register() {
      fetch('http://localhost:8024/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.user)
      })
      .then(response => {
        if (response.ok) {
          this.$router.push('/login');
        } else {
          // Handle other response statuses
          // Example: if (response.status === 400) { handleBadRequest(); }
          console.log("Error: ",this.user)
        }
      })
      .catch(error => {
        console.error('Error registering user:', error);
        // Handle network errors or other issues
      });
    }
  }
};
</script>
