<template>
  <div id="app">
    <nav>
      <router-link to="/">Home</router-link>
      <a href="#about" @click.prevent="scrollTo('about')">About</a>
      <a href="#resume" @click.prevent="scrollToSection('resume')">Resume</a>
      <router-link to="/posts">Blogs</router-link>
    </nav>

    <router-view />


  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoggedIn: false,
    };
  },
  methods: {
    scrollToSection(id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    goToLogin() {
      this.$router.push('/login');
    },
    logout() {
      // Implement logout logic (e.g., clear session or JWT)
      this.isLoggedIn = false;
      this.$router.push('/');
    },
  created() {
    // Check if user is logged in (e.g., check session or JWT)
    // This is a placeholder; you should implement proper authentication check
    this.isLoggedIn = false;
  },
  scrollTo(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
}
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');
#app {
  font-family: "poppins-medium", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 20px;
  background-color: #151515;
}

nav a {
  font-weight: bold;
  color: #42b983;
  text-decoration: none;
  margin: 0 15px;
}

nav a.router-link-exact-active {
  color: #42b983;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
  font-size: 1rem;
}
</style>
