import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { createHead } from '@vueuse/head';import App from './App.vue';
import AdminPage from './components/AdminPage.vue';
import BlogList from './components/BlogList.vue';
import RegisterPage from './components/RegisterPage.vue';
import LoginPage from './components/LoginPage.vue';
import HomePage from './components/HomePage.vue';
import './assets/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery';
import '@popperjs/core';


const routes = [
    { path: '/', component: HomePage },
    { path: '/posts', component: BlogList },
    { path: '/admin', component: AdminPage },
    { path: '/register', component: RegisterPage },
    { path: '/login', component: LoginPage },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const head = createHead()

createApp(App)
    .use(router)
    .use(head)
    .mount('#app');
