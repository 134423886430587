<template>
    <div class="row section-intro">
        <div class="col-twelve">

            <h5>About</h5>
            <h1>Let me introduce myself.</h1>

            <div class="intro-info">

                <img src="../assets/akash_suvarna.png" alt="Profile Picture">

                <p class="lead">Hello Visitor, I am Cloud Engineer with DevOps experience on AWS and Azure. Overall
                    industry experience of 6+ years and my work majorly is into ADO and AWS pipelines, Terraform,
                    GitOps, Docker, Python, Shell Scripting, Automation, Cognitive Services and much more...</p>
            </div>

        </div>
    </div> <!-- /section-intro -->

    <div class="row about-content">

        <div class="col-six tab-full">

            <h3>Profile</h3>
            <p>Here's a quick snap of my basic Profile Details</p>

            <ul class="info-list">
                <li>
                    <strong>Fullname:</strong>
                    <span>Akash Narayan Suvarna</span>
                </li>
                <li>
                    <strong>Birth Date:</strong>
                    <span>September 24, 1993</span>
                </li>
                <li>
                    <strong>Job:</strong>
                    <span>Cloud Engineer, DevOps</span>
                </li>
                <li>
                    <strong>Website:</strong>
                    <span>www.opswithakash.cloud</span>
                </li>
                <li>
                    <strong>Email:</strong>
                    <span>akash.suvarna@live.com</span>
                </li>

            </ul> <!-- /info-list -->

        </div>

        <div class="col-six tab-full skills">

            <h3>Skills</h3>
            <p>Here is a glance to my skills overview.</p>

            <strong>AWS</strong>
            <div class="progress" style="height: 20px;margin-bottom: 25px;">
                <div class="progress-bar" role="progressbar" style="width: 80%;" aria-valuenow="80" aria-valuemin="0"
                    aria-valuemax="100">80%</div>
            </div>

            <strong>Azure</strong>
            <div class="progress" style="height: 20px;margin-bottom: 25px;">
                <div class="progress-bar" role="progressbar" style="width: 75%;" aria-valuenow="75" aria-valuemin="0"
                aria-valuemax="100">75%</div>
            </div>

            <strong>Terraform</strong>
            <div class="progress" style="height: 20px;margin-bottom: 25px;">
                <div class="progress-bar" role="progressbar" style="width: 70%;" aria-valuenow="70" aria-valuemin="0"
                    aria-valuemax="100">70%</div>
            </div>

            <strong>Python</strong>
            <div class="progress" style="height: 20px;margin-bottom: 25px;">
                <div class="progress-bar" role="progressbar" style="width: 80%;" aria-valuenow="80" aria-valuemin="0"
                    aria-valuemax="100">80%</div>
            </div>

            <strong>Scripting</strong>
            <div class="progress" style="height: 20px;margin-bottom: 25px;">
                <div class="progress-bar" role="progressbar" style="width: 75%;" aria-valuenow="75" aria-valuemin="0"
                    aria-valuemax="100">75%</div>
            </div>

            <strong>Golang</strong>
            <div class="progress" style="height: 20px;margin-bottom: 25px;">
                <div class="progress-bar" role="progressbar" style="width: 50%;" aria-valuenow="50" aria-valuemin="0"
                    aria-valuemax="100">50%</div>
            </div>

            <strong>Database</strong>
            <div class="progress" style="height: 20px;margin-bottom: 25px;">
                <div class="progress-bar" role="progressbar" style="width: 70%;" aria-valuenow="70" aria-valuemin="0"
                    aria-valuemax="100">70%</div>
            </div>
        </div> <!-- /skill-bars -->

    </div>

    <div class="row button-section">
        <div class="col-twelve" id="resume">
            <a href="https://opswithakash-web.s3.ap-south-1.amazonaws.com/resume/Akash_Suvarna_Resume+-+Latest.pdf" title="Download CV" class="button button-primary">Download CV</a>
        </div>
    </div>
</template>
<script>


export default {
    name: 'AboutPage',

    data() {
        return {
            isLoggedIn: false,
        };
    },
    methods: {
        goToLogin() {
            this.$router.push('/login');
        },
    },
    created() {
        // Check if user is logged in (e.g., check session or JWT)
        // This is a placeholder; you should implement proper authentication check
        this.isLoggedIn = false;
    }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');
.section-intro {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 3.6rem;
    position: relative;
}

.about-content {
    position: relative;
    text-align: left;
    max-width: 850px;
    margin-bottom: 3.6rem;
    font-size: 20px;
}

.section-intro h5 {
    color: #FF0077;
    font-size: 1.6rem;
    font-family: "poppins-bold";
    text-transform: uppercase;
    line-height: 1.875;
    margin-bottom: 0.3rem;
    letter-spacing: .4rem;
}

.section-intro h1 {
    font-family: "poppins-semibold", serif;
    font-size: 48px;
    color: #313131;
    line-height: 1.25;
    margin-bottom: 1.2rem;
}

.intro-info {
    margin-top: 4.2rem;
    margin-right: -30px;
    margin-left: -30px;
}

.intro-info img {
    height: 9rem;
    width: 9rem;
    border-radius: 50%;
    margin: .9rem 0 0 0;
    float: left;
}

.intro-info .lead {
    text-align: left;
    padding-left: 13rem;
}

p.lead {
    font-family: "lora-regular", serif;
    font-size: 1.4rem;
    line-height: 1.8;
    color: #888888;
}
div.skills{
    font-size: 20px;
}

input,
textarea,
select,
pre,
blockquote,
figure,
table,
p,
ul,
ol,
dl,
form,
.fluid-video-wrapper,
.ss-custom-select {
    margin-bottom: 3rem;
}

.about-content {
    position: relative;
    text-align: left;
    max-width: 850px;
    margin-bottom: 3.6rem;
}

.col-six,
.col-1-2 {
    width: 50%;
}

[class*="col-"] {
    padding: 0 20px;
}

[class*="col-"],
.bgrid {
    float: left;
}

.row {
    width: 94%;
    max-width: 1140px;
    margin: 0 auto;
}

.about-content h3 {
    font-family: "poppins-bold", sans-serif;
    font-size: 1.8rem;
    text-transform: uppercase;
    letter-spacing: .25rem;
}

.about-content .info-list li strong {
    font-family: "poppins-bold", sans-serif;
    color: #313131;
    text-transform: uppercase;
    letter-spacing: .2rem;
    font-size: 1rem;
    line-height: 1rem;
}

.about-content .info-list {
    list-style: none;
    margin-left: 0;
}

.about-content .info-list li {
    padding: 0 0 1.5rem 0;
    margin-bottom: .6rem;
}

.about-content .skill-bars {
    margin-top: 6rem;
}

.skill-bars {
    list-style: none;
    margin: 6rem 0 3rem;
}

.skill-bars li {
    height: .6rem;
    background: #a1a1a1;
    width: 100%;
    margin-bottom: 6rem;
    padding: 0;
    position: relative;
}
.button-section [class*="col-"] .button:first-child {
  margin-right: 4rem;
  margin-top: 20px;
}

.button-section .button {
  width: 250px;
}
button.stroke, .button.stroke {
  background: transparent !important;
  border: 3px solid #313131;
  line-height: 4.8rem;
}
.button, a.button, button, input[type="submit"], input[type="reset"], input[type="button"] {
  display: inline-block;
  font-family: "poppins-bold", sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: .3rem;
  height: 3.4rem;
  line-height: 3.7rem;
  padding: 0 2rem;
  margin: 0 .3rem 1.2rem 0;
  background: #d8d8d8;
  color: #313131;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  border: none;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.button.button-primary, a.button.button-primary, button.button-primary, input[type="submit"].button-primary, input[type="reset"].button-primary, input[type="button"].button-primary {
  background: #313131;
  color: #FFFFFF;
}
a, a:visited {
  color: #000000;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}


</style>