<template>
  <div>
    <h1>Admin Page</h1>
    <form @submit.prevent="submitPost">
      <div>
        <label for="title">Title</label>
        <input type="text" v-model="newPost.title">
      </div>
      <div>
        <label for="content">Content</label>
        <textarea v-model="newPost.content"></textarea>
      </div>
      <button type="submit">Submit</button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newPost: {
        title: '',
        content: ''
      }
    };
  },
  methods: {
    submitPost() {
      fetch('http://localhost:8024/admin/posts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.newPost)
      }).then(() => {
        this.newPost.title = '';
        this.newPost.content = '';
      });
    }
  },
  created() {
    // Check if user is logged in (e.g., check session or JWT)
    // If not logged in, redirect to login page
    const isLoggedIn = false; // Implement actual check
    if (!isLoggedIn) {
      this.$router.push('/login');
    }
  }
};
</script>
