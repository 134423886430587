<template>
    <div>
        <!-- <h1>Welcome to the Blog</h1>
      <p>This is the homepage.</p>
        <button @click="goToLogin" v-if="!isLoggedIn">Login</button> -->
        <section id="intro">
            <div class="intro-overlay"></div>
            <div class="intro-content">
                <div class="row">
                    <div class="col-twelve">
                        <h5>Hello, Tech Enthusiasts.</h5>
                        <h1 style="font-size: 84px;">I'm Akash N. Suvarna</h1>
                        <p class="intro-position">
                            <span>DevOps Engineer</span>
                        </p>
                        <a class="button stroke smoothscroll" href="#about" title="">More About Me</a>

                    </div>
                </div>
            </div>
        </section>
        <section id="about">
      <AboutPage />
        </section>
    </div>
</template>

<script>
import AboutPage from './AboutPage.vue';
import { useHead } from '@vueuse/head';

export default {
    name: 'HomePage',
    components: {
        AboutPage
    },
    data() {
        return {
            isLoggedIn: false,
        };
    },
    methods: {
        goToLogin() {
            this.$router.push('/login');
        },
    },
    created() {
        // Check if user is logged in (e.g., check session or JWT)
        // This is a placeholder; you should implement proper authentication check
        this.isLoggedIn = false;
    },
    setup() {
        useHead({
            title: 'HomePage - OpsWithAkash',
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: 'All about Akash Suvarna, who is into DevOps and Cloud' },
                { name: 'author', content: '' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1' }
            ],
        });
    }
};
</script>
<style scoped>
.intro-content .button {
    color: #FFFFFF !important;
    border-color: rgba(255, 255, 255, 0.3);
    height: 6rem !important;
    line-height: 5.4rem !important;
    padding: 1.5rem !important;
    margin-top: .6rem;
    font-size: 1.3rem;
    text-transform: uppercase;
    letter-spacing: .25rem;
}
button.stroke, .button.stroke {
    background: transparent !important;
    border: 3px solid #313131;
    line-height: 4.8rem;
}

#about {
    background: #FFFFFF;
    padding-top: 6rem;
    padding-bottom: 2rem;
}
a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}
</style>